//react
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MdArrowDropDown, MdArrowDropUp, MdArrowLeft } from "react-icons/md";

//images
import yocalyLogo from "../../components/images/yocalyThumb.png";
import yocalyLogoWide from "../../components/images/yocaly/yocaly-logo.png";
import yocalyBanner from "../../components/images/yocaly/yocaly-banner.png";
import yocalyFlow from "../../components/images/yocaly/yocaly-flow.png";
import yocalyColour from "../../components/images/yocaly/yocaly-colour.png";
import yocalyType from "../../components/images/yocaly/yocaly-type.png";
import yocalyExample from "../../components/images/yocaly/yocaly-example.png";

//other
import "../ProjectsTitle.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const YocalyTitle = () => {
  let isIntroDone = false;

  let anim = gsap.timeline();
  let animDistance = "-10vh";
  let animDuration = 0.5;
  let animTrans = "transparent";
  let animColour = "#fff";

  //intro animations
  useEffect(() => {
    anim
      .fromTo(
        ".projects-intro",
        { y: "-25%", opacity: 0 },
        {
          y: 0,
          duration: animDuration,
          opacity: 1,
        }
      )
      .to(
        ".projects-intro",
        {
          y: "25%",
          duration: animDuration,
          opacity: 0,
        },
        "+=0.2"
      )
      .fromTo(
        ".projects-header-container",
        { y: animDistance, opacity: 0 },
        {
          y: 0,
          duration: animDuration,
          opacity: 1,
        }
      )
      .fromTo(
        ".projects-text",
        { y: animDistance, color: animTrans },
        {
          y: 0,
          duration: animDuration,
          color: animColour,
        },
        "-=0.3"
      )
      .fromTo(
        ".projects-image",
        { opacity: 0, y: "-10%" },
        {
          opacity: 1,
          y: "0",
          stagger: 0.1,
        },
        "-=0.5"
      )
      .fromTo(
        ".projects-scroll-button",
        { opacity: 0, y: "-50%" },
        {
          opacity: 1,
          y: "0",
          stagger: 0.1,
        }
      );

    isIntroDone = true;
  }, [isIntroDone === false]);

  const ScrollDown = () => {
    const scroller = document.querySelector(".scrollable-projects");
    scroller.scrollBy(0, 200);
  };

  const ScrollUp = () => {
    const scroller = document.querySelector(".scrollable-projects");
    scroller.scrollBy(0, -200);
  };

  return (
    <>
      <div className="scrollable-projects">
        <div className="projects-intro">
          <img
            src={yocalyLogo}
            alt="intro"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="projects-header-container">
          <div className="projects-title">
            <p>
              YOCALY <br /> LTD.
            </p>
          </div>
          <Link className="projects-back" to="/designer">
            <MdArrowLeft />
          </Link>
        </div>
        <div className="projects-text">
          <p>
            Yocaly are a small scale devivery service based in the North West,
            when they came to me for a logo and banner they already had a good
            idea of what they wanted, text which included some reference to
            location and the colour green, to signify both that they are a local
            service and to signify friendlyness and imply environmentalist ideas
            respectively. The logo was to be used on both digital pages as well
            as to be emproided on to employee uniforms, I feel the logo fits
            both cases perfectly being scalable, as well as easily embroided
            given its simplicity and wide typeface. I hope to work with them in
            the future as they expand.
          </p>
        </div>
        <div className="projects-section">
          <div className="projects-image image-span">
            <img
              src={yocalyLogoWide}
              alt="cloud"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image image-span">
            <img
              src={yocalyBanner}
              alt="cloud"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image image-span">
            <img
              src={yocalyFlow}
              alt="cloud"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image">
            <img
              src={yocalyColour}
              alt="cloud"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image">
            <img
              src={yocalyType}
              alt="cloud"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image image-span">
            <img
              src={yocalyExample}
              alt="cloud"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="projects-buttons-container">
        <button className="projects-scroll-button" onClick={() => ScrollUp()}>
          <MdArrowDropUp />
        </button>
        <button className="projects-scroll-button" onClick={() => ScrollDown()}>
          <MdArrowDropDown />
        </button>
      </div>
    </>
  );
};

export default YocalyTitle;
