//react
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

//other
import "./Footer.css";
import { gsap } from "gsap";

export default function Footer({
  menuClicked,
  menuActive,
  textColour,
  lineColour,
}) {
  let rootStyle = document.querySelector(":root");

  let isIntroDone = false;

  let menuSpeed = 0.2;
  let menuOpen = gsap.timeline();
  let menuClose = gsap.timeline();

  //intro animation and set colour of text underline
  useEffect(() => {
    rootStyle.style.setProperty("--underline-colour", "transparent");
    setTimeout(() => {
      gsap.fromTo(
        ".footer-text",
        { y: "-100%", color: "transparent" },
        { y: "0px", color: textColour }
      );
      gsap.fromTo(
        ".hamburger-line",
        { y: "-100%", borderBottom: "1px solid transparent" },
        { y: "0px", borderBottom: lineColour, stagger: 0.1 }
      );
      rootStyle.style.setProperty("--underline-colour", textColour);
      isIntroDone = true;
    }, 2000);
  }, [isIntroDone === false]);

  //hamburger animations
  const menuAnimation = () => {
    if (menuActive === false) {
      menuOpen
        .to(".hamburger-line-0", { marginBottom: "-1px", duration: menuSpeed })
        .to(
          ".hamburger-line-2",
          { marginTop: "-1px", duration: menuSpeed },
          "-=0.2"
        )
        .to(".hamburger-line-1", { opacity: 0, duration: menuSpeed }, "-=0.2")
        .to(".hamburger-line-0", {
          transform: "rotate(45deg)",
          duration: menuSpeed,
        })
        .to(
          ".hamburger-line-2",
          { transform: "rotate(-45deg)", duration: menuSpeed },
          "-=0.2"
        );
    }

    if (menuActive) {
      menuClose
        .to(".hamburger-line-0", {
          transform: "rotate(0deg)",
          duration: menuSpeed,
        })
        .to(
          ".hamburger-line-2",
          { transform: "rotate(0deg)", duration: menuSpeed },
          "-=0.2"
        )
        .to(".hamburger-line-0", { marginBottom: "8px", duration: menuSpeed })
        .to(
          ".hamburger-line-2",
          { marginTop: "8px", duration: menuSpeed },
          "-=0.2"
        )
        .to(".hamburger-line-1", { opacity: 1, duration: menuSpeed }, "-=0.2");
    }
  };

  return (
    <div className="footer">
      <div className="footer-info">
        <p className="footer-text">
          BASED IN <br /> THE UK
        </p>
        <p className="footer-text">
          CURR. WORKING <br /> FREELANCE
        </p>
      </div>
      <div className="footer-info-mobile">
        <p className="footer-text">
          BASED <br /> IN THE <br /> UK
        </p>
        <p className="footer-text">
          CURR. <br /> WORKING <br /> FREELANCE
        </p>
      </div>

      <div className="footer-menu">
        <Link style={{ textDecoration: "none", color: textColour }} to="/">
          <p className="footer-text link-underline">HOME</p>
        </Link>
        <Link style={{ textDecoration: "none", color: textColour }} to="/about">
          <p className="footer-text link-underline">ABOUT</p>
        </Link>
      </div>

      <div
        className="footer-hamburger"
        onClick={() => {
          menuClicked();
          menuAnimation();
        }}
      >
        <div className="hamburger-line-0 hamburger-line"></div>
        <div className="hamburger-line-1 hamburger-line"></div>
        <div className="hamburger-line-2 hamburger-line"></div>
      </div>
    </div>
  );
}
