//react
import React, { useEffect, useRef, useState } from "react";
import Div100vh from "react-div-100vh";
import { Link } from "react-router-dom";

//other
import "./Home.css";
import { gsap } from "gsap";

//components
import Banner from "./components/Banner";
import Footer from "./components/Footer";

function Home() {
  //hamburger stuff
  let [menuActive, setMenuActive] = useState(false);
  let menuSpeed = 0.6;
  let menuOpen = gsap.timeline();
  let menuClose = gsap.timeline();

  const menuClicked = () => {
    if (menuActive === false) {
      menuOpen
        .to(".banner-container", {
          duration: menuSpeed,
          height: "0%",
        })
        .to(
          ".banner-container",
          {
            duration: menuSpeed,
            opacity: 0,
            duration: menuSpeed / 2,
          },
          "-=0.6"
        )
        .to(
          ".line",
          {
            duration: 0,
            opacity: 0,
          },
          "-=0.6"
        )
        .to(
          ".menu-container",
          {
            duration: menuSpeed,
            height: "85%",
            padding: "4vw 0 0 0",
          },
          "-=0.6"
        )
        .fromTo(
          ".menu-text",
          { y: "100%", opacity: 0 },
          {
            duration: menuSpeed,
            y: 0,
            opacity: 1,
            stagger: 0.1,
          },
          "-=0.6"
        )
        .fromTo(
          ".menu-line-divider",
          {
            width: "0%",
            borderBottom: "1px solid #fffffff",
          },
          {
            duration: menuSpeed,
            width: "100%",
            borderBottom: "1px solid #ffffff",
          },
          "-=0.6"
        );

      setMenuActive(true);
    }

    if (menuActive === true) {
      menuClose
        .to(".banner-container", {
          duration: menuSpeed,
          height: "85%",
        })
        .to(
          ".menu-container",
          {
            duration: menuSpeed,
            height: "0%",
            padding: "0",
          },
          "-=0.6"
        )
        .to(".line", {
          duration: 0,
          opacity: 1,
        })
        .fromTo(
          ".menu-text",
          { opacity: 1 },
          {
            duration: menuSpeed,
            opacity: 0,
          },
          "-=0.6"
        )
        .to(
          ".banner-container",
          {
            duration: 0,
            opacity: 1,
          },
          "-=0.3"
        )
        .fromTo(
          ".row",
          { y: "-100%", opacity: 0 },
          {
            y: 0,
            duration: menuSpeed / 2,
            opacity: 1,
            stagger: 0.05,
          },
          "-=0.3"
        );

      setMenuActive(false);
    }
  };

  //intro stuff
  let isIntroDone = false;

  useEffect(() => {
    setTimeout(() => {
      gsap.fromTo(
        ".container",
        { backgroundColor: "#fff" },
        {
          duration: 0.5,
          backgroundColor: "#111212",
        }
      );
    }, 1000);
    setTimeout(() => {
      gsap.fromTo(
        ".line",
        { y: "-40px", borderBottom: "1px solid transparent" },
        { y: "0px", borderBottom: "1px solid #ffffff" }
      );
    }, 1800);
    isIntroDone = true;
  }, [isIntroDone === false]);

  return (
    <>
      <Div100vh className="container">
        <div className="banner-container">
          <Banner />
        </div>

        <div className="menu-container">
          <div className="menu-line"></div>

          <Link style={{ textDecoration: "none", color: "#fff" }} to="/">
            <p className="menu-text">HOME</p>
          </Link>
          <Link style={{ textDecoration: "none", color: "#fff" }} to="/about">
            <p className="menu-text">ABOUT</p>
          </Link>
          <div className="menu-line-divider"></div>
          <Link
            style={{ textDecoration: "none", color: "#fff" }}
            to="/designer"
          >
            <p className="menu-text">DESIGNER</p>
          </Link>
          <Link style={{ textDecoration: "none" }} className="menu-text" to="/">
            <p>CODER</p>
            <p className="menu-coming-soon">COMING SOON</p>
          </Link>
          <Link style={{ textDecoration: "none" }} className="menu-text">
            <p>ARTIST</p>
            <p className="menu-coming-soon">COMING SOON</p>
          </Link>
        </div>

        <div className="footer-container">
          <div className="line"></div>
          <Footer
            menuClicked={menuClicked}
            menuActive={menuActive}
            lineColour={"1px solid #fff"}
            textColour={"#fff"}
          />
        </div>
      </Div100vh>
    </>
  );
}

export default Home;
