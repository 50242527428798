//react
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MdArrowDropDown, MdArrowDropUp, MdArrowLeft } from "react-icons/md";

//images
import lpbLogo from "../../components/images/lpbThumb.png";
import lpbLogoDark from "../../components/images/lpb/lpb-logo-dark.png";
import lpbLogoLight from "../../components/images/lpb/lpb-logo-light.png";
import lpbExample0 from "../../components/images/lpb/lpb-example-0.png";
import lpbExample1 from "../../components/images/lpb/lpb-example-1.png";
import lpbExample2 from "../../components/images/lpb/lpb-example-2.png";
import lpbFont from "../../components/images/lpb/lpb-font.png";
import lpbPuzzle from "../../components/images/lpb/lpb-puzzle.png";
import lpbPuzzleFlow from "../../components/images/lpb/lpb-puzzle-flow.png";
import lpbIcons from "../../components/images/lpb/lpb-icons.png";
import lpbIconsFlow from "../../components/images/lpb/lpb-icons-flow.png";
import lpbColours from "../../components/images/lpb/lpb-colours.png";

//other
import "../ProjectsTitle.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const LPBTitle = () => {
  let isIntroDone = false;

  let anim = gsap.timeline();
  let animDistance = "-10vh";
  let animDuration = 0.5;
  let animTrans = "transparent";
  let animColour = "#fff";

  //intro animations
  useEffect(() => {
    anim
      .fromTo(
        ".projects-intro",
        { y: "-25%", opacity: 0 },
        {
          y: 0,
          duration: animDuration,
          opacity: 1,
        }
      )
      .to(
        ".projects-intro",
        {
          y: "25%",
          duration: animDuration,
          opacity: 0,
        },
        "+=0.2"
      )
      .fromTo(
        ".projects-header-container",
        { y: animDistance, opacity: 0 },
        {
          y: 0,
          duration: animDuration,
          opacity: 1,
        }
      )
      .fromTo(
        ".projects-text",
        { y: animDistance, color: animTrans },
        {
          y: 0,
          duration: animDuration,
          color: animColour,
        },
        "-=0.3"
      )
      .fromTo(
        ".projects-image",
        { opacity: 0, y: "-10%" },
        {
          opacity: 1,
          y: "0",
          stagger: 0.1,
        },
        "-=0.5"
      )
      .fromTo(
        ".projects-scroll-button",
        { opacity: 0, y: "-50%" },
        {
          opacity: 1,
          y: "0",
          stagger: 0.1,
        }
      );

    isIntroDone = true;
  }, [isIntroDone === false]);

  const ScrollDown = () => {
    const scroller = document.querySelector(".scrollable-projects");
    scroller.scrollBy(0, 200);
  };

  const ScrollUp = () => {
    const scroller = document.querySelector(".scrollable-projects");
    scroller.scrollBy(0, -200);
  };

  return (
    <>
      <div className="scrollable-projects">
        <div className="projects-intro">
          <img
            src={lpbLogo}
            alt="intro"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="projects-header-container">
          <div className="projects-title">
            <p>
              LPB <br /> SOLUTIONS
            </p>
          </div>
          <Link className="projects-back" to="/designer">
            <MdArrowLeft />
          </Link>
        </div>
        <div className="projects-text">
          <p>
            LPB, was a management consultancy services run by Lynette Ousby, I
            provided a logo and iconography for her website through Source
            Kinetic. They wanted a serif logo with a professional family
            business feel. As this is older work I feel I would do this slightly
            differently today however I do enjoy the puzzle icon I designed.
          </p>
        </div>
        <div className="projects-section">
          <div className="projects-image image-span">
            <img
              src={lpbLogoDark}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image image-span">
            <img
              src={lpbLogoLight}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image">
            <img
              src={lpbExample0}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image">
            <img
              src={lpbExample1}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image">
            <img
              src={lpbExample2}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image">
            <img
              src={lpbFont}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image">
            <img
              src={lpbPuzzle}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image">
            <img
              src={lpbPuzzleFlow}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image image-span">
            <img
              src={lpbIcons}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image image-span">
            <img
              src={lpbIconsFlow}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image image-span">
            <img
              src={lpbColours}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="projects-buttons-container">
        <button className="projects-scroll-button" onClick={() => ScrollUp()}>
          <MdArrowDropUp />
        </button>
        <button className="projects-scroll-button" onClick={() => ScrollDown()}>
          <MdArrowDropDown />
        </button>
      </div>
    </>
  );
};

export default LPBTitle;
