//react
import React, { useEffect, useRef, useState } from "react";
import Div100vh from "react-div-100vh";
import { Link } from "react-router-dom";

//other
import "./Designer.css";
import { gsap } from "gsap";

//components
import DesignerTitle from "./components/DesignerTitle";
import Footer from "./components/Footer";

function Home() {
  //hamburger stuff
  let [menuActive, setMenuActive] = useState(false);
  let menuSpeed = 0.6;
  let menuOpen = gsap.timeline();
  let menuClose = gsap.timeline();

  const menuClicked = () => {
    if (menuActive === false) {
      menuOpen
        .to(".title-container", {
          duration: menuSpeed,
          height: "0%",
        })
        .to(
          ".title-container",
          {
            duration: menuSpeed,
            opacity: 0,
            duration: menuSpeed / 2,
          },
          "-=0.6"
        )
        .to(
          ".title-line",
          {
            duration: 0,
            opacity: 0,
          },
          "-=0.6"
        )
        .to(
          ".title-menu-container",
          {
            duration: menuSpeed,
            height: "85%",
            padding: "4vw 0 0 0",
          },
          "-=0.6"
        )
        .fromTo(
          ".title-menu-text",
          { y: "100%", opacity: 0 },
          {
            duration: menuSpeed,
            y: 0,
            opacity: 1,
            stagger: 0.1,
          },
          "-=0.6"
        )
        .fromTo(
          ".title-menu-line-divider",
          {
            width: "0%",
            borderBottom: "1px solid #111212",
          },
          {
            duration: menuSpeed,
            width: "100%",
            borderBottom: "1px solid #111212",
          },
          "-=0.6"
        );

      setMenuActive(true);
    }

    if (menuActive === true) {
      menuClose
        .to(".title-container", {
          duration: menuSpeed,
          height: "85%",
        })
        .to(
          ".title-menu-container",
          {
            duration: menuSpeed,
            height: "0%",
            padding: "0",
          },
          "-=0.6"
        )
        .to(".title-line", {
          duration: 0,
          opacity: 1,
        })
        .fromTo(
          ".title-menu-text",
          { opacity: 1 },
          {
            duration: menuSpeed,
            opacity: 0,
          },
          "-=0.6"
        )
        .to(
          ".title-container",
          {
            duration: 0,
            opacity: 1,
          },
          "-=0.3"
        )
        .fromTo(
          ".title-row",
          { y: "-100%", opacity: 0 },
          {
            y: 0,
            duration: menuSpeed / 2,
            opacity: 1,
            stagger: 0.05,
          },
          "-=0.3"
        )
        .fromTo(
          ".gallery-item",
          { opacity: 0, y: "-10%" },
          {
            opacity: 1,
            y: "0",
            stagger: 0.1,
          },
          "-=0.3"
        );

      setMenuActive(false);
    }
  };

  //intro stuff
  let isIntroDone = false;

  useEffect(() => {
    gsap.fromTo(
      ".white-container",
      { backgroundColor: "#111212" },
      {
        duration: 1,
        backgroundColor: "#fff",
      }
    );
    setTimeout(() => {
      gsap.fromTo(
        ".title-line",
        { y: "-40px", borderBottom: "1px solid #11121200" },
        { y: "0px", borderBottom: "1px solid #111212" }
      );
    }, 1800);
    isIntroDone = true;
  }, [isIntroDone === false]);

  return (
    <Div100vh className="white-container">
      <div className="title-container">
        <DesignerTitle />
      </div>

      <div className="title-menu-container">
        <div className="title-menu-line"></div>

        <Link style={{ textDecoration: "none", color: "#111212" }} to="/">
          <p className="title-menu-text">HOME</p>
        </Link>
        <Link style={{ textDecoration: "none", color: "#111212" }} to="/about">
          <p className="title-menu-text">ABOUT</p>
        </Link>
        <div className="title-menu-line-divider"></div>
        <Link
          style={{ textDecoration: "none", color: "#111212" }}
          to="/designer"
        >
          <p className="title-menu-text">DESIGNER</p>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          to="/designer"
          className="title-menu-text"
        >
          <p>CODER</p>
          <p className="title-menu-coming-soon">COMING SOON</p>
        </Link>
        <Link style={{ textDecoration: "none" }} className="title-menu-text">
          <p>ARTIST</p>
          <p className="title-menu-coming-soon">COMING SOON</p>
        </Link>
      </div>

      <div className="footer-container">
        <div className="title-line"></div>
        <Footer
          menuClicked={menuClicked}
          menuActive={menuActive}
          lineColour={"1px solid #111212"}
          textColour={"#111212"}
        />
      </div>
    </Div100vh>
  );
}

export default Home;
