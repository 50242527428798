//react
import React, { useEffect, useRef } from "react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

//other
import "./AboutTitle.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const AboutTitle = () => {
  let isIntroDone = false;

  let anim = gsap.timeline();
  let animDistance = "-10vh";
  let animDuration = 0.5;
  let animDelay = 0.05;
  let animTrans = "transparent";
  let animColour = "#fff";

  //intro animations
  const introLargeRef = useRef(null);
  const introSmallRef = useRef(null);
  useEffect(() => {
    const introLarge = introLargeRef.current;
    const introSmall = introSmallRef.current;
    anim
      .fromTo(
        ".about-row",
        { y: animDistance, color: animTrans },
        {
          y: 0,
          duration: animDuration,
          color: animColour,
          stagger: animDelay,
        },
        "+=1"
      )
      .fromTo(
        introLarge,
        { y: animDistance, color: animTrans },
        {
          y: 0,
          duration: animDuration,
          color: animColour,
          stagger: animDelay,
        },
        "-=0.1"
      )
      .fromTo(
        introSmall,
        { y: animDistance, color: animTrans },
        {
          y: 0,
          duration: animDuration,
          color: animColour,
          stagger: animDelay,
        },
        "-=0.4"
      )
      .fromTo(
        ".intro-arrow",
        { y: animDistance, color: animTrans },
        {
          y: 0,
          duration: animDuration,
          color: animColour,
        },
        "-=0.4"
      )
      .fromTo(
        ".about-scroll-button",
        { opacity: 0, y: "-50%" },
        {
          opacity: 1,
          y: "0",
          stagger: 0.1,
        },
        "-=0.5"
      )
      .fromTo(
        ".top-line",
        { width: 0, borderBottom: "1px solid #ffffff00" },
        {
          width: "100%",
          duration: animDuration * 2,
          borderBottom: "1px solid #ffffff",
        }
      );

    isIntroDone = true;
  }, [isIntroDone === false]);

  //scroll activated animations
  //Services Section References
  const servicesRef = useRef(null);
  const graphicsLineRef = useRef(null);
  const graphicsLargeRef = useRef(null);
  const graphicsSmallRef = useRef(null);
  const webLineRef = useRef(null);
  const webLargeRef = useRef(null);
  const webSmallRef = useRef(null);
  const threeLineRef = useRef(null);
  const threeLargeRef = useRef(null);
  const threeSmallRef = useRef(null);
  //Employment Section References
  const employmentRef = useRef(null);
  const genLineRef = useRef(null);
  const genLargeRef = useRef(null);
  const genSmallRef = useRef(null);
  const barLineRef = useRef(null);
  const barLargeRef = useRef(null);
  const barSmallRef = useRef(null);
  const recycleLineRef = useRef(null);
  const recycleLargeRef = useRef(null);
  const recycleSmallRef = useRef(null);
  const landscapeLineRef = useRef(null);
  const landscapeLargeRef = useRef(null);
  const landscapeSmallRef = useRef(null);
  useEffect(() => {
    //Services Section References
    const servicesTitle = servicesRef.current;
    const graphicsLine = graphicsLineRef.current;
    const graphicsLarge = graphicsLargeRef.current;
    const graphicsSmall = graphicsSmallRef.current;
    const webLine = webLineRef.current;
    const webLarge = webLargeRef.current;
    const webSmall = webSmallRef.current;
    const threeLine = threeLineRef.current;
    const threeLarge = threeLargeRef.current;
    const threeSmall = threeSmallRef.current;
    //Employment Section References
    const employmentTitle = employmentRef.current;
    const genLine = genLineRef.current;
    const genLarge = genLargeRef.current;
    const genSmall = genSmallRef.current;
    const barLine = barLineRef.current;
    const barLarge = barLargeRef.current;
    const barSmall = barSmallRef.current;
    const recycleLine = recycleLineRef.current;
    const recycleLarge = recycleLargeRef.current;
    const recycleSmall = recycleSmallRef.current;
    const landscapeLine = landscapeLineRef.current;
    const landscapeLarge = landscapeLargeRef.current;
    const landscapeSmall = landscapeSmallRef.current;

    //Services Section Animations
    gsap.fromTo(
      servicesTitle,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: servicesTitle,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );

    //graphics animations
    gsap.fromTo(
      graphicsLine,
      { y: animDistance, borderBottom: "1px solid #ffffff00" },
      {
        scrollTrigger: {
          trigger: graphicsLine,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        borderBottom: "1px solid #ffffff",
      }
    );
    gsap.fromTo(
      graphicsLarge,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: graphicsLarge,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );
    gsap.fromTo(
      graphicsSmall,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: graphicsSmall,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );

    //web animations
    gsap.fromTo(
      webLine,
      { y: animDistance, borderBottom: "1px solid #ffffff00" },
      {
        scrollTrigger: {
          trigger: webLine,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        borderBottom: "1px solid #ffffff",
      }
    );
    gsap.fromTo(
      webLarge,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: webLarge,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );
    gsap.fromTo(
      webSmall,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: webSmall,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );

    //3d animations
    gsap.fromTo(
      threeLine,
      { y: animDistance, borderBottom: "1px solid #ffffff00" },
      {
        scrollTrigger: {
          trigger: threeLine,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        borderBottom: "1px solid #ffffff",
      }
    );
    gsap.fromTo(
      threeLarge,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: threeLarge,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );
    gsap.fromTo(
      threeSmall,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: threeSmall,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );

    //Employment Section Animations
    gsap.fromTo(
      employmentTitle,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: employmentTitle,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );

    //gener8 animations
    gsap.fromTo(
      genLine,
      { y: animDistance, borderBottom: "1px solid #ffffff00" },
      {
        scrollTrigger: {
          trigger: genLine,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        borderBottom: "1px solid #ffffff",
      }
    );
    gsap.fromTo(
      genLarge,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: genLarge,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );
    gsap.fromTo(
      genSmall,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: genSmall,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );

    //bartending animations
    gsap.fromTo(
      barLine,
      { y: animDistance, borderBottom: "1px solid #ffffff00" },
      {
        scrollTrigger: {
          trigger: barLine,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        borderBottom: "1px solid #ffffff",
      }
    );
    gsap.fromTo(
      barLarge,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: barLarge,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );
    gsap.fromTo(
      barSmall,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: barSmall,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );

    //recycleIT animations
    gsap.fromTo(
      recycleLine,
      { y: animDistance, borderBottom: "1px solid #ffffff00" },
      {
        scrollTrigger: {
          trigger: recycleLine,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        borderBottom: "1px solid #ffffff",
      }
    );
    gsap.fromTo(
      recycleLarge,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: recycleLarge,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );
    gsap.fromTo(
      recycleSmall,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: recycleSmall,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );

    //landscaper animations
    gsap.fromTo(
      landscapeLine,
      { y: animDistance, borderBottom: "1px solid #ffffff00" },
      {
        scrollTrigger: {
          trigger: landscapeLine,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        borderBottom: "1px solid #ffffff",
      }
    );
    gsap.fromTo(
      landscapeLarge,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: landscapeLarge,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );
    gsap.fromTo(
      landscapeSmall,
      { y: animDistance, color: animTrans },
      {
        scrollTrigger: {
          trigger: landscapeSmall,
          start: "top center+=300",
          scroller: ".scrollable-about",
        },
        y: 0,
        duration: animDuration,
        color: animColour,
      }
    );
  });

  const ScrollDown = () => {
    const scroller = document.querySelector(".scrollable-about");
    scroller.scrollBy(0, 200);
  };

  const ScrollUp = () => {
    const scroller = document.querySelector(".scrollable-about");
    scroller.scrollBy(0, -200);
  };

  return (
    <>
      <div className="scrollable-about">
        <div className="about-title">
          <div className="about-row-1 about-row">
            <p>SAM BRADLEY</p>
          </div>
          <div className="about-row-2 about-row">
            <p>ABOUT</p>
          </div>
        </div>
        <div className="about-intro">
          <div className="top-line"></div>
          <p className="intro-large" ref={introLargeRef}>
            DIGITAL DESIGNER <br /> BASED IN THE NORTH <br /> WEST OF ENGLAND
          </p>
          <p className="intro-small" ref={introSmallRef}>
            Prior to my work in 3D and software development, I have developed a
            particular interest in working in graphic design. The ability to
            exercise creativity alongside the knowledge of producing something
            with real-world functionality, very much appeals to both my skillset
            and my aspirations. This marriage of skillsets, has not only
            inspired my venture, but imposed itself upon it. In studying (and on
            occasion, producing) graphic design, alongside my main background in
            game development - I have found myself approaching all projects with
            a unique angle, with each skillset complimenting the other, leading
            to the production of work I am consistently proud to offer.
          </p>
        </div>
        <p className="about-section-title-services" ref={servicesRef}>
          SERVICES
        </p>
        <div className="about-services">
          <div className="about-line-divider" ref={graphicsLineRef}></div>
          <p className="intro-large" ref={graphicsLargeRef}>
            GRAPHIC DESIGN
          </p>
          <p className="intro-small" ref={graphicsSmallRef}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            at dolor nec nulla gravida elementum eu vitae ipsum. Praesent
            placerat turpis vel risus sodales, eu malesuada odio volutpat.
            Quisque varius ligula nec convallis iaculis. Pellentesque habitant
            morbi tristique senectus et netus et malesuada fames ac turpis
            egestas. Etiam risus odio, mattis in egestas vitae, posuere vel ex.
            Pellentesque bibendum ligula a tempor elementum. Nulla ut vehicula
            arcu. Nulla in lectus nisi. Quisque suscipit non ex ut imperdiet.
          </p>
          <div className="about-line-divider" ref={webLineRef}></div>
          <p className="intro-large" ref={webLargeRef}>
            WEB DESIGN
          </p>
          <p className="intro-small" ref={webSmallRef}>
            Sed lacinia erat nunc. Maecenas mattis ligula viverra nisi accumsan,
            ut rhoncus ipsum pretium. Fusce eu varius lorem. Nullam vitae dolor
            consectetur, condimentum purus ac, malesuada dui. Fusce dapibus
            porttitor tincidunt. Quisque venenatis sodales dictum. Sed euismod
            nulla at risus hendrerit tincidunt id nec nisi. Aenean accumsan eros
            purus, vitae dapibus justo placerat a. Pellentesque ac molestie
            diam.
          </p>
          <div className="about-line-divider" ref={threeLineRef}></div>
          <p className="intro-large" ref={threeLargeRef}>
            3D DESIGN
          </p>
          <p className="intro-small" ref={threeSmallRef}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            at dolor nec nulla gravida elementum eu vitae ipsum. Praesent
            placerat turpis vel risus sodales, eu malesuada odio volutpat.
            Quisque varius ligula nec convallis iaculis. Pellentesque habitant
            morbi tristique senectus et netus et malesuada fames ac turpis
            egestas. Etiam risus odio, mattis in egestas vitae, posuere vel ex.
            Pellentesque bibendum ligula a tempor elementum. Nulla ut vehicula
            arcu. Nulla in lectus nisi. Quisque suscipit non ex ut imperdiet.
          </p>
        </div>
        <p className="about-section-title-employment" ref={employmentRef}>
          EMPLOYMENT
        </p>
        <div className="about-employment">
          <div className="about-line-divider" ref={genLineRef}></div>
          <p className="intro-large" ref={genLargeRef}>
            GENER8 SPACES
          </p>
          <p className="intro-small" ref={genSmallRef}>
            After speaking with the directors of Gener8 about their project, to
            implement their fully interactive, touch sensitive classrooms - I
            was quick to jump in as their multimedia design lead. In this role,
            I developed the software they continue to use for learners across
            the UK, predominantly using C# in the Unity framework. Additionally,
            I produced graphic design work along with motion graphic animations
            for their promotional videos, in order to attract interested
            clients.
          </p>
          <div className="about-line-divider" ref={barLineRef}></div>
          <p className="intro-large" ref={barLargeRef}>
            BARTENDING : VARIOUS
          </p>
          <p className="intro-small" ref={barSmallRef}>
            Whilst studying games design at university, I enjoyed working at a
            variety of cocktail bars. In this role I would interact with
            customers, recommending and selling the drinks memorised from the
            menu (as well as any classic cocktails possible from our
            ingredients), and took pride in maintaining a clean, well stocked
            bar at the end of each night.
          </p>
          <div className="about-line-divider" ref={recycleLineRef}></div>
          <p className="intro-large" ref={recycleLargeRef}>
            RECYCLE IT
          </p>
          <p className="intro-small" ref={recycleSmallRef}>
            In my time at home from university, such as over the holidays, I
            would undertake work at Recycle-IT; collecting, assessing and if
            need be repairing the IT equipment donated to us such as old Dell
            OptiPlex desktops - as well as organising their warehouse and
            individual pallets.
          </p>
          <div className="about-line-divider" ref={landscapeLineRef}></div>
          <p className="intro-large" ref={landscapeLargeRef}>
            LANDSCAPE ENGINEERING
          </p>
          <p className="intro-small" ref={landscapeSmallRef}>
            Before college, I worked as a landscaper, laying flags and tarmac as
            well as general gardening work such as digging and fencing. During
            the pandemic, I again undertook this role, as the company had an
            excess of work - my services were requested as my previous work with
            them was of a reportedly high standard.
          </p>
        </div>
      </div>
      <div className="about-buttons-container">
        <button className="about-scroll-button" onClick={() => ScrollUp()}>
          <MdArrowDropUp />
        </button>
        <button className="about-scroll-button" onClick={() => ScrollDown()}>
          <MdArrowDropDown />
        </button>
      </div>
    </>
  );
};

export default AboutTitle;
