//react
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

//other
import "./Banner.css";
import { gsap } from "gsap";

export const Banner = () => {
  let isIntroDone = false;

  let anim = gsap.timeline();
  let animDistance = "-100%";
  let animDuration = 0.5;
  let animDelay = 0.06;
  let animTrans = "transparent";
  let animColour = "#ffffff";
  let animIntro = "#111212";

  useEffect(() => {
    anim
      .to(".row", { opacity: 0, duration: 0 })
      .fromTo(
        ".intro-text",
        { y: "-100%", color: animTrans },
        {
          y: 0,
          duration: 1,
          color: animIntro,
          stagger: 0.1,
        }
      )
      .to(".intro-text", {
        y: "100%",
        duration: animDuration,
        color: animTrans,
        stagger: 0.1,
      })
      .to(".row", { opacity: 1, duration: 0 }, "-=0.2")
      .to(".intro-text", { display: "none", duration: 0 })
      .fromTo(
        ".row",
        { y: animDistance, color: animTrans },
        {
          y: 0,
          duration: animDuration,
          color: animColour,
          stagger: animDelay,
        },
        "-=0.5"
      );

    isIntroDone = true;
  }, [isIntroDone === false]);

  return (
    <>
      <div className="banner">
        <div className="intro">
          <p className="intro-text">SAM BRADLEY</p>
          <p className="intro-text">FOLIO /22</p>
        </div>

        <div className="row-1 row">
          <p className="name">SAM BRADLEY</p>
        </div>
        {/*regular desktop div tags*/}
        <div className="row-2 row">
          <Link className="text-hover designer" to="/designer">
            <p className="designer-hyper">GRAPHIC</p>
            <p>DESIGNER</p>
          </Link>
          <Link className="coder-text-hover coder" to="/">
            <p className="">CODER</p>
            <p className="coder-coming-soon">COMING SOON</p>
          </Link>
        </div>
        <div className="row-3 row">
          <Link className="text-hover artist" to="/">
            <p className="artist-hyper">3D</p>
            <p>ARTIST</p>
            <p className="artist-coming-soon">COMING SOON</p>
          </Link>
          <Link className="text-hover" to="/designer">
            <p>FOLIO</p>
          </Link>
        </div>
        <div className="row-4 row">
          <p>/22</p>
        </div>

        {/*responsive div tags*/}
        <div className="row-2-alt row">
          <Link className="text-hover designer" to="/designer">
            <p className="designer-hyper">GRAPHIC</p>
            <p>DESIGNER</p>
          </Link>
        </div>
        <div className="row-3-alt row">
          <Link className="coder-text-hover coder" to="/">
            <p>CODER</p>
            <p className="coder-coming-soon">COMING SOON</p>
          </Link>
        </div>
        <div className="row-4-alt row">
          <Link className="text-hover artist" to="/">
            <p className="artist-hyper">3D</p>
            <p>ARTIST</p>
            <p className="artist-coming-soon">COMING SOON</p>
          </Link>
          <Link className="text-hover" to="/designer">
            <p>FOLIO</p>
          </Link>
        </div>
        <div className="row-5-alt row">
          <p>/22</p>
        </div>
      </div>
    </>
  );
};

export default Banner;
