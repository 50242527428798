//react
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MdArrowDropDown, MdArrowDropUp, MdArrowLeft } from "react-icons/md";

//images
import questLogo from "../../components/images/questThumb.png";
import questPoster from "../../components/images/posters/quest.png";
import dezzyPoster from "../../components/images/posters/dezzy.png";

//other
import "../ProjectsTitle.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const PostersTitle = () => {
  let isIntroDone = false;

  let anim = gsap.timeline();
  let animDistance = "-10vh";
  let animDuration = 0.5;
  let animTrans = "transparent";
  let animColour = "#fff";

  //intro animations
  useEffect(() => {
    anim
      .fromTo(
        ".projects-intro",
        { y: "-25%", opacity: 0 },
        {
          y: 0,
          duration: animDuration,
          opacity: 1,
        }
      )
      .to(
        ".projects-intro",
        {
          y: "25%",
          duration: animDuration,
          opacity: 0,
        },
        "+=0.2"
      )
      .fromTo(
        ".projects-header-container",
        { y: animDistance, opacity: 0 },
        {
          y: 0,
          duration: animDuration,
          opacity: 1,
        }
      )
      .fromTo(
        ".projects-text",
        { y: animDistance, color: animTrans },
        {
          y: 0,
          duration: animDuration,
          color: animColour,
        },
        "-=0.3"
      )
      .fromTo(
        ".projects-image",
        { opacity: 0, y: "-10%" },
        {
          opacity: 1,
          y: "0",
          stagger: 0.1,
        },
        "-=0.5"
      )
      .fromTo(
        ".projects-scroll-button",
        { opacity: 0, y: "-50%" },
        {
          opacity: 1,
          y: "0",
          stagger: 0.1,
        }
      );

    isIntroDone = true;
  }, [isIntroDone === false]);

  const ScrollDown = () => {
    const scroller = document.querySelector(".scrollable-projects");
    scroller.scrollBy(0, 200);
  };

  const ScrollUp = () => {
    const scroller = document.querySelector(".scrollable-projects");
    scroller.scrollBy(0, -200);
  };

  return (
    <>
      <div className="scrollable-projects">
        <div className="projects-intro">
          <img
            src={questLogo}
            alt="intro"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="projects-header-container">
          <div className="projects-title">
            <p>
              PARKOUR <br /> POSTERS
            </p>
          </div>
          <Link className="projects-back" to="/designer">
            <MdArrowLeft />
          </Link>
        </div>
        <div className="projects-text">
          <p>
            As I occasionally train parkour, a lot of my friends tend to be very
            active in the parkour community. More recently I have done some
            small bits of work for them when we host events, below are some
            posters I made for them when they were hosting events this year. The
            first he wanted a fantasy vibe which I think I hit on the head and I
            chose the red because England, the second doesn't really have any
            reason my friend just really wanted it in that style as he thinks
            its eye catching and cool.
          </p>
        </div>
        <div className="projects-section">
          <div className="projects-image">
            <img
              src={questPoster}
              alt="poster"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image">
            <img
              src={dezzyPoster}
              alt="poster"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="projects-buttons-container">
        <button className="projects-scroll-button" onClick={() => ScrollUp()}>
          <MdArrowDropUp />
        </button>
        <button className="projects-scroll-button" onClick={() => ScrollDown()}>
          <MdArrowDropDown />
        </button>
      </div>
    </>
  );
};

export default PostersTitle;
