//react
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

//other
import "./index.css";

//components
import Home from "./Home.js";
import Designer from "./Designer.js";
import About from "./About.js";
import Cloud from "./projects/cloud/Cloud.js";
import Yocaly from "./projects/yocaly/Yocaly";
import Gen from "./projects/gen/Gen";
import Posters from "./projects/posters/Posters";
import Dom from "./projects/dom/Dom";
import LPB from "./projects/lpb/LPB";
import GenLogo from "./projects/genLogo/GenLogo";
import Freewill from "./projects/freewill/Freewill";
import Rev from "./projects/rev/Rev";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/designer" element={<Designer />} />
        <Route path="/cloud" element={<Cloud />} />
        <Route path="/yocaly" element={<Yocaly />} />
        <Route path="/gen" element={<Gen />} />
        <Route path="/posters" element={<Posters />} />
        <Route path="/dom" element={<Dom />} />
        <Route path="/lpb" element={<LPB />} />
        <Route path="/genlogo" element={<GenLogo />} />
        <Route path="/freewill" element={<Freewill />} />
        <Route path="/revolution" element={<Rev />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
