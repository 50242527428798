//react
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MdArrowDropDown, MdArrowDropUp, MdArrowLeft } from "react-icons/md";

//images
import genLogo from "../../components/images/genThumb.png";
import genLogoLarge from "../../components/images/genLogo/genLogo.png";
import genLogoSmall from "../../components/images/genLogo/genLogo-small.png";
import genLogoFlow from "../../components/images/genLogo/genLogo-flow.png";
import genLogoColours from "../../components/images/genLogo/genLogo-colours.png";

//other
import "../ProjectsTitle.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const LPBTitle = () => {
  let isIntroDone = false;

  let anim = gsap.timeline();
  let animDistance = "-10vh";
  let animDuration = 0.5;
  let animTrans = "transparent";
  let animColour = "#fff";

  //intro animations
  useEffect(() => {
    anim
      .fromTo(
        ".projects-intro",
        { y: "-25%", opacity: 0 },
        {
          y: 0,
          duration: animDuration,
          opacity: 1,
        }
      )
      .to(
        ".projects-intro",
        {
          y: "25%",
          duration: animDuration,
          opacity: 0,
        },
        "+=0.2"
      )
      .fromTo(
        ".projects-header-container",
        { y: animDistance, opacity: 0 },
        {
          y: 0,
          duration: animDuration,
          opacity: 1,
        }
      )
      .fromTo(
        ".projects-text",
        { y: animDistance, color: animTrans },
        {
          y: 0,
          duration: animDuration,
          color: animColour,
        },
        "-=0.3"
      )
      .fromTo(
        ".projects-image",
        { opacity: 0, y: "-10%" },
        {
          opacity: 1,
          y: "0",
          stagger: 0.1,
        },
        "-=0.5"
      )
      .fromTo(
        ".projects-scroll-button",
        { opacity: 0, y: "-50%" },
        {
          opacity: 1,
          y: "0",
          stagger: 0.1,
        }
      );

    isIntroDone = true;
  }, [isIntroDone === false]);

  const ScrollDown = () => {
    const scroller = document.querySelector(".scrollable-projects");
    scroller.scrollBy(0, 200);
  };

  const ScrollUp = () => {
    const scroller = document.querySelector(".scrollable-projects");
    scroller.scrollBy(0, -200);
  };

  return (
    <>
      <div className="scrollable-projects">
        <div className="projects-intro">
          <img
            src={genLogo}
            alt="intro"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="projects-header-container">
          <div className="projects-title">
            <p>
              GENER8 <br /> SPACES
            </p>
          </div>
          <Link className="projects-back" to="/designer">
            <MdArrowLeft />
          </Link>
        </div>
        <div className="projects-text">
          <p>
            Also while at Gener8 I created an alternative colourway for our logo
            which was used for the icons and splashscreen of my software, this
            was (and still is to my knowledge) being used as the "Digital" logo
            at Gener8. This was simply to create a fun logo to represent the
            software aspect of the business.
          </p>
        </div>
        <div className="projects-section">
          <div className="projects-image image-span">
            <img
              src={genLogoLarge}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image">
            <img
              src={genLogoSmall}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image">
            <img
              src={genLogoFlow}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image image-span">
            <img
              src={genLogoColours}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="projects-buttons-container">
        <button className="projects-scroll-button" onClick={() => ScrollUp()}>
          <MdArrowDropUp />
        </button>
        <button className="projects-scroll-button" onClick={() => ScrollDown()}>
          <MdArrowDropDown />
        </button>
      </div>
    </>
  );
};

export default LPBTitle;
