//react
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

//other
import "./DesignerTitle.css";
import { gsap } from "gsap";

//images
import cloudThumb from "./images/cloudThumb.png";
import yocalyThumb from "./images/yocalyThumb.png";
import genThumb from "./images/genThumb.png";
import lpbThumb from "./images/lpbThumb.png";
import domThumb from "./images/domThumb.png";
import questThumb from "./images/questThumb.png";
import freewillThumb from "./images/freewillThumb.png";
import revThumb from "./images/revThumb.png";

export const DesignerTitle = () => {
  let isIntroDone = false;

  let anim = gsap.timeline();
  let animDistance = "-100%";
  let animDuration = 0.5;
  let animDelay = 0.05;
  let animTrans = "transparent";
  let animColour = "#111212";

  useEffect(() => {
    anim
      .fromTo(
        ".title-row",
        { y: animDistance, color: animTrans },
        {
          y: 0,
          duration: animDuration,
          color: animColour,
          stagger: animDelay,
        },
        "+=1"
      )
      .fromTo(
        ".title-line-divider",
        { y: "-50px", borderBottom: "1px solid transparent" },
        {
          y: 0,
          duration: animDuration,
          borderBottom: "1px solid #111212",
        },
        "-=0.5"
      )
      .fromTo(
        ".gallery-item",
        { opacity: 0, y: "-10%" },
        {
          opacity: 1,
          y: "0",
          stagger: 0.1,
        },
        "-=0.5"
      )
      .fromTo(
        ".scroll-button",
        { opacity: 0, y: "-50%" },
        {
          opacity: 1,
          y: "0",
          stagger: 0.1,
        },
        "-=0.5"
      );

    isIntroDone = true;
  }, [isIntroDone === false]);

  const ScrollDown = () => {
    const scroller = document.querySelector(".scrollable");
    scroller.scrollBy(0, 200);
  };

  const ScrollUp = () => {
    const scroller = document.querySelector(".scrollable");
    scroller.scrollBy(0, -200);
  };

  return (
    <>
      <div className="scrollable">
        <div className="title">
          {/*regular desktop div tags*/}
          <div className="title-row-1 title-row">
            <p className="title-name">SAM BRADLEY</p>
            <div className="title-title">
              <p className="title-hyper">GRAPHIC</p>
              <p>DESIGNER</p>
            </div>
          </div>
          <div className="title-row-2 title-row">
            <p>FOLIO</p>
          </div>
          <div className="title-row-3 title-row title-underline">
            <p>/22</p>
          </div>

          {/*responsive div tags*/}
          <div className="title-row-1-alt title-row">
            <p className="title-name">SAM BRADLEY</p>
          </div>
          <div className="title-row-2-alt title-row">
            <div className="title-title">
              <p className="title-hyper">GRAPHIC</p>
              <p>DESIGNER</p>
            </div>
          </div>
          <div className="title-row-3-alt title-row">
            <p>FOLIO</p>
          </div>
          <div className="title-row-4-alt title-row">
            <p>/22</p>
          </div>
        </div>
        {/*gallery*/}
        <div className="title-line-divider" />
        <div className="gallery-section">
          <Link className="gallery-item" to="/cloud">
            <div className="gallery-image">
              <img
                src={cloudThumb}
                alt="thumbnail"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="gallery-text">
              <div style={{ textAlign: "left" }}>
                <p>BRANDING</p>
              </div>
              <div style={{ textAlign: "right" }}>
                <p>CLOUD CUCKOO</p>
                <p>2022</p>
              </div>
            </div>
          </Link>
          <Link className="gallery-item" to="/yocaly">
            <div className="gallery-image">
              <img
                src={yocalyThumb}
                alt="thumbnail"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="gallery-text">
              <div style={{ textAlign: "left" }}>
                <p>BRANDING</p>
              </div>
              <div style={{ textAlign: "right" }}>
                <p>YOCALY LTD</p>
                <p>2021</p>
              </div>
            </div>
          </Link>
          <Link className="gallery-item" to="/gen">
            <div className="gallery-image">
              <img
                src={genThumb}
                alt="thumbnail"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="gallery-text">
              <div style={{ textAlign: "left" }}>
                <p>SOCIAL MEDIA / INFOGRAPHICS</p>
              </div>
              <div style={{ textAlign: "right" }}>
                <p>GENER8 SPACES</p>
                <p>2022</p>
              </div>
            </div>
          </Link>
          <Link className="gallery-item" to="/posters">
            <div className="gallery-image">
              <img
                src={questThumb}
                alt="thumbnail"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="gallery-text">
              <div style={{ textAlign: "left" }}>
                <p>POSTERS</p>
              </div>
              <div style={{ textAlign: "right" }}>
                <p>VARIOUS</p>
                <p>2022</p>
              </div>
            </div>
          </Link>
          <Link className="gallery-item" to="/dom">
            <div className="gallery-image">
              <img
                src={domThumb}
                alt="thumbnail"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="gallery-text">
              <div style={{ textAlign: "left" }}>
                <p>CHANNEL ARTWORK</p>
              </div>
              <div style={{ textAlign: "right" }}>
                <p>FREEDOM GAMING</p>
                <p>2022</p>
              </div>
            </div>
          </Link>
          <Link className="gallery-item" to="/lpb">
            <div className="gallery-image">
              <img
                src={lpbThumb}
                alt="thumbnail"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="gallery-text">
              <div style={{ textAlign: "left" }}>
                <p>WEBSITE / LOGO</p>
              </div>
              <div style={{ textAlign: "right" }}>
                <p>LPB SOLUTIONS</p>
                <p>2020</p>
              </div>
            </div>
          </Link>
          <Link className="gallery-item" to="/genlogo">
            <div className="gallery-image">
              <img
                src={genThumb}
                alt="thumbnail"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="gallery-text">
              <div style={{ textAlign: "left" }}>
                <p>BRANDING</p>
              </div>
              <div style={{ textAlign: "right" }}>
                <p>GENER8 SPACES</p>
                <p>2022</p>
              </div>
            </div>
          </Link>
          <Link className="gallery-item" to="/freewill">
            <div className="gallery-image">
              <img
                src={freewillThumb}
                alt="thumbnail"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="gallery-text">
              <div style={{ textAlign: "left" }}>
                <p>BANNER</p>
              </div>
              <div style={{ textAlign: "right" }}>
                <p>FREEWILL MEDIA</p>
                <p>2020</p>
              </div>
            </div>
          </Link>
          <Link className="gallery-item" to="/revolution">
            <div className="gallery-image">
              <img
                src={revThumb}
                alt="thumbnail"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="gallery-text">
              <div style={{ textAlign: "left" }}>
                <p>SIGNAGE</p>
              </div>
              <div style={{ textAlign: "right" }}>
                <p>REVOLUTION BAR</p>
                <p>2020</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="buttons-container">
        <button className="scroll-button" onClick={() => ScrollUp()}>
          <MdArrowDropUp />
        </button>
        <button className="scroll-button" onClick={() => ScrollDown()}>
          <MdArrowDropDown />
        </button>
      </div>
    </>
  );
};

export default DesignerTitle;
