//react
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MdArrowDropDown, MdArrowDropUp, MdArrowLeft } from "react-icons/md";

//images
import cloudLogo from "../../components/images/cloudThumb.png";
import cloudGreen from "../../components/images/cloud/cloud-green.png";
import cloudPurple from "../../components/images/cloud/cloud-purple.png";
import cloudFlow from "../../components/images/cloud/cloud-flow.png";
import cloudType from "../../components/images/cloud/cloud-type.png";
import cloudColours from "../../components/images/cloud/cloud-colours.png";

//other
import "../ProjectsTitle.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const CloudTitle = () => {
  let isIntroDone = false;

  let anim = gsap.timeline();
  let animDistance = "-10vh";
  let animDuration = 0.5;
  let animTrans = "transparent";
  let animColour = "#fff";

  //intro animations
  useEffect(() => {
    anim
      .fromTo(
        ".projects-intro",
        { y: "-25%", opacity: 0 },
        {
          y: 0,
          duration: animDuration,
          opacity: 1,
        }
      )
      .to(
        ".projects-intro",
        {
          y: "25%",
          duration: animDuration,
          opacity: 0,
        },
        "+=0.2"
      )
      .fromTo(
        ".projects-header-container",
        { y: animDistance, opacity: 0 },
        {
          y: 0,
          duration: animDuration,
          opacity: 1,
        }
      )
      .fromTo(
        ".projects-text",
        { y: animDistance, color: animTrans },
        {
          y: 0,
          duration: animDuration,
          color: animColour,
        },
        "-=0.3"
      )
      .fromTo(
        ".projects-image",
        { opacity: 0, y: "-10%" },
        {
          opacity: 1,
          y: "0",
          stagger: 0.1,
        },
        "-=0.5"
      )
      .fromTo(
        ".projects-scroll-button",
        { opacity: 0, y: "-50%" },
        {
          opacity: 1,
          y: "0",
          stagger: 0.1,
        }
      );

    isIntroDone = true;
  }, [isIntroDone === false]);

  const ScrollDown = () => {
    const scroller = document.querySelector(".scrollable-projects");
    scroller.scrollBy(0, 200);
  };

  const ScrollUp = () => {
    const scroller = document.querySelector(".scrollable-projects");
    scroller.scrollBy(0, -200);
  };

  return (
    <>
      <div className="scrollable-projects">
        <div className="projects-intro">
          <img
            src={cloudLogo}
            alt="intro"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="projects-header-container">
          <div className="projects-title">
            <p>
              CLOUD <br /> CUCKOO
            </p>
          </div>
          <Link className="projects-back" to="/designer">
            <MdArrowLeft />
          </Link>
        </div>
        <div className="projects-text">
          <p>
            Cloud Cuckoo are a small startup digital marketing agency, providing
            copywriting and social media management, with hopes to expand in the
            future. When they came to me for branding material, they wanted - as
            the name might suggest - a playful and colourful logo. I then set
            out creating a logo in the fun and dynamic style of 70s psycadellic
            typography, I chose this style as I feel it gives you a fun eye
            catching feel while still meeting the requirements of modern logo
            design, that being scalability, memorability and brand.
          </p>
        </div>
        <div className="projects-section">
          <div className="projects-image image-span">
            <img
              src={cloudGreen}
              alt="cloud"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image image-span">
            <img
              src={cloudPurple}
              alt="cloud"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image">
            <img
              src={cloudFlow}
              alt="cloud"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image">
            <img
              src={cloudType}
              alt="cloud"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="projects-image image-span">
            <img
              src={cloudColours}
              alt="cloud"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="projects-buttons-container">
        <button className="projects-scroll-button" onClick={() => ScrollUp()}>
          <MdArrowDropUp />
        </button>
        <button className="projects-scroll-button" onClick={() => ScrollDown()}>
          <MdArrowDropDown />
        </button>
      </div>
    </>
  );
};

export default CloudTitle;
