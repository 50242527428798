//react
import React, { useEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import { Link } from "react-router-dom";

//other
import "../Projects.css";
import { gsap } from "gsap";

//components
import DomTitle from "./DomTitle";
import Footer from "../../components/Footer";

function Dom() {
  //hamburger stuff
  let [menuActive, setMenuActive] = useState(false);
  let menuSpeed = 0.6;
  let menuOpen = gsap.timeline();
  let menuClose = gsap.timeline();

  const menuClicked = () => {
    if (menuActive === false) {
      menuOpen
        .to(".projects-title-container", {
          duration: menuSpeed,
          height: "0%",
        })
        .to(
          ".projects-title-container",
          {
            duration: menuSpeed,
            opacity: 0,
            duration: menuSpeed / 2,
          },
          "-=0.6"
        )
        .to(
          ".projects-line",
          {
            duration: 0,
            opacity: 0,
          },
          "-=0.6"
        )
        .to(
          ".projects-menu-container",
          {
            duration: menuSpeed,
            height: "85%",
            padding: "4vw 0 0 0",
          },
          "-=0.6"
        )
        .fromTo(
          ".projects-menu-text",
          { y: "100%", opacity: 0 },
          {
            duration: menuSpeed,
            y: 0,
            opacity: 1,
            stagger: 0.1,
          },
          "-=0.6"
        )
        .fromTo(
          ".projects-menu-line-divider",
          {
            width: "0%",
            borderBottom: "1px solid #fff",
          },
          {
            duration: menuSpeed,
            width: "100%",
            borderBottom: "1px solid #fff",
          },
          "-=0.6"
        );

      setMenuActive(true);
    }

    if (menuActive === true) {
      menuClose
        .to(".projects-title-container", {
          duration: menuSpeed,
          height: "85%",
        })
        .to(
          ".projects-menu-container",
          {
            duration: menuSpeed,
            height: "0%",
            padding: "0",
          },
          "-=0.6"
        )
        .to(".projects-line", {
          duration: 0,
          opacity: 1,
        })
        .fromTo(
          ".projects-menu-text",
          { opacity: 1 },
          {
            duration: menuSpeed,
            opacity: 0,
          },
          "-=0.6"
        )
        .to(
          ".projects-title-container",
          {
            duration: menuSpeed,
            opacity: 1,
          },
          "-=0.3"
        )
        .fromTo(
          ".projects-row",
          { y: "-100%", opacity: 0 },
          {
            y: 0,
            duration: menuSpeed / 2,
            opacity: 1,
            stagger: 0.05,
          },
          "-=0.3"
        );

      setMenuActive(false);
    }
  };

  //intro stuff
  let isIntroDone = false;

  useEffect(() => {
    setTimeout(() => {
      gsap.fromTo(
        ".projects-container",
        { backgroundColor: "#fff" },
        {
          duration: 0.5,
          backgroundColor: "#111212",
        }
      );
    }, 1000);

    setTimeout(() => {
      gsap.fromTo(
        ".projects-line",
        { y: "-40px", borderBottom: "1px solid #ffffff00" },
        { y: "0px", borderBottom: "1px solid #fff" }
      );
    }, 1800);
    isIntroDone = true;
  }, [isIntroDone === false]);

  return (
    <Div100vh className="projects-container">
      <div className="projects-title-container">
        <DomTitle />
      </div>

      <div className="projects-menu-container">
        <div className="projects-menu-line"></div>

        <Link style={{ textDecoration: "none", color: "#fff" }} to="/">
          <p className="projects-menu-text">HOME</p>
        </Link>
        <Link style={{ textDecoration: "none", color: "#fff" }} to="/about">
          <p className="projects-menu-text">ABOUT</p>
        </Link>
        <div className="projects-menu-line-divider"></div>
        <Link style={{ textDecoration: "none", color: "#fff" }} to="/designer">
          <p className="projects-menu-text">DESIGNER</p>
        </Link>
        <Link style={{ textDecoration: "none" }} className="projects-menu-text">
          <p>CODER</p>
          <p className="projects-menu-coming-soon">COMING SOON</p>
        </Link>
        <Link style={{ textDecoration: "none" }} className="projects-menu-text">
          <p>ARTIST</p>
          <p className="projects-menu-coming-soon">COMING SOON</p>
        </Link>
      </div>

      <div className="footer-container">
        <div className="projects-line"></div>
        <Footer
          menuClicked={menuClicked}
          menuActive={menuActive}
          lineColour={"1px solid #fff"}
          textColour={"#fff"}
        />
      </div>
    </Div100vh>
  );
}

export default Dom;
